import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { terms as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';

import {
    Hero,
    SEO,
} from '@brandedholdings/react-components';

import PageWrapper from '../components/DefaultPageWrapper';

import "../stylesheets/global.scss";



class Terms extends React.Component {
    getChildContext() {
        return { siteMeta };
    }
    
    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper>
                <SEO post={ post } />
                <Hero
                    heading="Terms & Conditions"
                    subheading={null}
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--empty"
                    actionModifiers={null}
                />
                <div className="hero__accent icon icon--arrow-dotted"></div>


                <div className="layout">
                    <div className="layout-content">
                        <div className="layout">
                            <div className="layout-content">
                                

                                <p><strong>IMPORTANT: LEGAL TERMS OF WEBSITE USE</strong></p>


                                <p>
Welcome to
                            {' '}
                            { siteMeta.name }
                            {' '}
(the “Company”) Web Site (the “Site”)! Your access to and use of
                            the Site is subject to the Terms of Website Use set forth in this Agreement.

                        </p>

                        <p>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING THE SITE.</p>

                        <p className="text-uppercase">
BY USING THE SITE, YOU WILL BE ACKNOWLEDGING THAT YOU HAVE READ
                            THIS AGREEMENT AND AGREED WITHOUT LIMITATION OR QUALIFICATION, TO BE LEGALLY BOUND BY THIS
                            AGREEMENT AND THE TERMS OF THE
                            {' '}
                            { siteMeta.name }
                            <a href="/privacy-policy/" className="button-text-link">
                                PRIVACY POLICY
                            </a>
                            . THESE TERMS OF WEBSITE USE CONTAIN A MANDATORY ARBITRATION PROVISION THAT REQUIRES YOU TO
                            ARBITRATE INDIVIDUALLY ANY DISPUTES OR CLAIMS YOU MAY HAVE WITH US AND WAIVES YOUR RIGHT TO
                            PARTICIPATE IN A CLASS ACTION OR MULTI-PARTY ARBITRATION (SEE BELOW).
                        </p>

                        <p>
IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF WEBSITE USE, YOU MAY NOT ACCESS OR USE THE
                            SITE.

                        </p>

                        <p>
THE COMPANY MAY, FROM TIME TO TIME, MAKE CHANGES OR ADDITIONS TO THIS AGREEMENT, AND YOUR
                            CONTINUED USE OF THE SITE AFTER THE COMPANY MAKES SUCH CHANGES OR ADDITIONS WILL SIGNIFY
                            THAT YOU AGREE TO SUCH CHANGES OR ADDITIONS. YOU SHOULD THEREFORE PERIODICALLY VISIT THIS
                            PAGE TO REVIEW THE THEN CURRENT TERMS OF WEBSITE USE.

                        </p>

                        <h4>Authorized Uses</h4>

                        <p>
The Company, authorizes you to use this Site and to display its content but solely for your
                            own personal noncommercial use. Any other use is strictly prohibited. You agree not to
                            reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any
                            material at the Site, use of the Site, or access to the Site. By using this Site you
                            acknowledge that you have read and understood the terms of the
                            {' '}
                            { siteMeta.name }
                            {' '}
Privacy
                            Policy and agree to said terms.

                        </p>

                        <h4>Proprietary Rights</h4>

                        <p>
Everything that appears on the Site is protected under the copyright laws of the United
                            States and other countries and may not be used except as provided in these Terms of Website
                            Use. The Company neither represents nor warrants that your use of materials displayed at the
                            Site will not infringe rights of third parties.

                        </p>

                        <h4>Inaccuracies at the Site</h4>

                        <p>
While the Company uses reasonable efforts to include accurate and up to date information at
                            the Site, the Company makes no warranties or representations as to the Site’s accuracy. The
                            Company disclaims any and all liability for the accuracy, completeness, or correctness of
                            such information.

                        </p>

                        <h4>Security at the Site</h4>

                        <p>
While the Company uses reasonable efforts to safeguard the security of the Site, there can be
                            no guaranty that such safeguards will successfully prevent unauthorized alterations in the
                            content or functionality of the Site. The Company assumes no liability or responsibility for
                            any unauthorized alterations in the content or functionality of the Site.

                        </p>

                        <h4>Disclaimer of Warranties</h4>

                        <p>
Neither the Company nor any other party involved in creating, producing, or delivering the
                            Site makes any representations about the suitability of the content of this Site for any
                            purpose, nor that your use of the Site will be uninterrupted or error-free. EVERYTHING ON
                            THIS SITE IS PROVIDED TO YOU “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR
                            IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                            FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Please note that some jurisdictions may not
                            allow the exclusions of implied warranties, so some of the above exclusions may not apply to
                            you. Check your local laws for any restrictions or limitations regarding the exclusion of
                            implied warranties.

                        </p>

                        <h4>Limitation of Liabilities</h4>

                        <p>
Neither the Company nor any other party involved in creating, producing, or delivering the
                            Site assumes any responsibility, and shall not be liable for, any damages to, or viruses
                            that may infect, your computer equipment or other property on account of your access to, use
                            of, or browsing in the Site or your downloading of any materials, data, text, images, video,
                            or audio from this Site. Your use of and browsing in the Site are at your risk. NO SUCH
                            PARTY SHALL BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, OR PUNITIVE
                            DAMAGES ARISING OUT OF YOUR ACCESS TO, OR USE OF, THE SITE. IN NO EVENT SHALL THE COMPANY’S
                            TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER IN CONTRACT,
                            WARRANTY, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,
                            EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE.

                        </p>

                        <h4>Content Submitted or Made Available for Inclusion at the Site</h4>

                        <p>
Any unsolicited communication or material that you transmit to the Site by electronic mail or
                            otherwise, including any data, questions, comments, suggestions, or the like is, and will be
                            treated as, non-confidential and non-proprietary. Any unsolicited communication or material
                            that you transmit or post may be used by the Company or its affiliates for any purpose,
                            including, but not limited to, reproduction, disclosure, transmission, publication,
                            broadcasting, distribution or posting. Furthermore, the Company will be free to use any
                            ideas, concepts, know-how, or techniques contained in any unsolicited communication or
                            material that you send to the Site for any purpose whatsoever including, but not limited to
                            developing, manufacturing and marketing products using such information.

                        </p>

                        <h4>Images of People or Places</h4>

                        <p>
Images of people or places displayed on the Site are either the property of, or used with
                            permission by, the Company. Any unauthorized use of the images may violate copyright laws,
                            trademark laws, the laws of privacy and publicity, and communications regulations and
                            statutes.

                        </p>

                        <h4>Trademark Information</h4>

                        <p>
The trademarks, logos, and service marks (collectively the “Trademarks”) displayed on the
                            Site, are registered and unregistered Trademarks of the Company and others. Nothing
                            contained on the Site should be construed as granting, by implication, estoppel, or
                            otherwise, any license or right to use any Trademark displayed on the Site without the
                            written permission of the Company or such third-party that may own the Trademarks displayed
                            on the Site. Your use of the Trademarks displayed on the Site, or any other content on the
                            Site, except as provided in these Terms of Website Use, is strictly prohibited. You are also
                            advised the Company will aggressively enforce its intellectual property rights to the
                            fullest extent of the law, including the seeking of criminal prosecution.

                        </p>

                        <h4>Links</h4>

                        <p>
The Company has not reviewed any sites that may be linked to the Site and is not responsible
                            for the content of any off-site pages or any other sites linked to the Site. Your linking to
                            any other off-site pages or other sites is at your own risk.

                        </p>

                        <h4>Conduct of Site Visitors</h4>

                        <p>
Although the Company may from time to time monitor or review postings, transmissions, and the
                            like on the Site, the Company is under no obligation to do so and assumes no responsibility
                            or liability arising from the content of any such locations nor for any error, defamation,
                            libel, slander, omission, falsehood, obscenity, pornography, profanity, danger, or
                            inaccuracy contained in any information within such locations on the Site. You are
                            prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory,
                            obscene, scandalous, inflammatory, pornographic, or profane material or any material that
                            could constitute or encourage conduct that would be considered a criminal offense, give rise
                            to civil liability, or otherwise violate any law. The Company retains the right to remove
                            any such posting and will fully cooperate with any law enforcement authorities or court
                            order requesting or directing the Company to disclose the identity of anyone posting any
                            such information or materials.

                        </p>

                        <h4>Dealings with Advertisers</h4>

                        <p>
Your correspondence or business dealings with, or participation in promotions of, advertisers
                            found on or through the Site, including payment and delivery of related goods or services,
                            and any other terms, conditions, warranties or representations associated with such
                            dealings, are solely between you and such advertiser. You agree that the Company shall not
                            be responsible or liable for any loss or damage of any sort incurred as the result of any
                            such dealings or as the result of the presence of such advertisers on the Site.

                        </p>

                        <h4>Notices</h4>

                        <p>
The Company may give notice to its users by means of a general notice on this Site,
                            electronic mail to a user’s e-mail address on its records, or by written communication sent
                            by first class mail to a user’s address on its records.

                        </p>

                        <h4>International Use</h4>

                        <p>
None of the products or underlying information or technology available at this Site may be
                            downloaded or otherwise exported (i) into (or to a national or resident of) Cuba, Iraq,
                            Iran, Libya, North Korea, Sudan, Syria, or any other country to which the United States has
                            embargoed goods; or (ii) to anyone on the United Stated Treasury Department’s list of
                            Specially Designated Nationals or the U.S. Commerce Department’s Table of Deny Orders. By
                            downloading from, or using the Site, you represent and warrant that you are not located in,
                            under the control of, or a national or resident of any such country or on any such list. You
                            further agree to indemnify the Company against any all costs, liabilities, losses or
                            expenses arising from, or relating to, any asserted violation by you of any of the laws and
                            administrative regulations of the United States relating to the control of exports of
                            commodities and technical data.

                        </p>

                        <h4>Monitoring, Copying, Altering or Interfering With the Site</h4>

                        <p>
You agree that you will not use any robot, spider, Web crawler, screen scraper, automated
                            query program or other automatic device or manual process to monitor or copy our web pages
                            or the content contained herein without our prior expressed written permission. You agree
                            that you will not use any device, software or routine to interfere or attempt to interfere
                            with the proper working of the Site. You agree that you will not take any action that
                            imposes an unreasonable or disproportionately large load on our infrastructure. You agree
                            that you will not copy, reproduce, alter, modify, create derivative works, or publicly
                            display any content from our Web site without the prior expressed written permission of the
                            Company or the appropriate third-party.

                        </p>

                        <h4>General Information</h4>

                        <p>
These Terms of Website Use shall be governed by and construed in accordance with the laws of
                            the state of Delaware, without giving effect to any principles of conflicts of law. Without
                            limiting the general applicability of the arbitration provision set forth in Section 17
                            below, you agree that any action at law or in equity arising out of or relating to these
                            Terms of Website Use shall be filed only in the state or federal courts located in Sussex
                            County, Delaware and you hereby consent and submit to the personal jurisdiction of such
                            courts for the purposes of litigating any such action. If any provisions of these Terms of
                            Website Use shall be unlawful, void, or for any reason unenforceable, then the provision
                            shall be deemed severable from these Terms of Website Use and shall not affect the validity
                            and enforceability of any remaining provisions. Our failure to act with respect to a breach
                            by you or others does not waive our right to act with respect to subsequent or similar
                            breaches. This Agreement sets forth the entire understanding and agreement between us with
                            respect to the subject matter hereof.

                        </p>

                        <h4>Mandatory Arbitration</h4>

                        <p>
If you have any dispute concerning any aspect of these Terms of Website Use, the Website, or
                            any of our services, you agree to submit your dispute for resolution by arbitration before
                            the American Arbitration Association (“AAA”) in the county where you live by filing a Demand
                            for Arbitration. The arbitrator will have exclusive authority to resolve any dispute
                            including any claim that all or any part of these Terms of Website Use are
                            unenforceable.

                        </p>

                        <p>
If we have a dispute, we will submit our dispute for resolution by arbitration before the AAA
                            in the state of Delaware. If either party files for arbitration, it will be conducted in
                            accordance with the then current AAA Commercial Arbitration Rules. The arbitrator will have
                            exclusive authority to resolve any dispute including any claim related to your use of the
                            Website, or any of our services. If either party proceeds to arbitration, each party will
                            pay its own AAA filing, administration and arbitrator fees unless the arbitrator determines
                            that a party’s claim is frivolous or brought for an improper purpose (as measured by the
                            standards set forth in Federal Rule of Civil Procedure 11(b)), in which case the party
                            submitting such claim shall pay for all filing, administration and arbitrator fees. For
                            claims brought by you of Ten Thousand Dollars ($10,000.00) or less, you can choose whether
                            the arbitration proceeds in person, by telephone or based only on submissions. The
                            arbitrator may award any form of individual or equitable relief, including injunctive
                            relief. Any award will be final and conclusive to the parties and may be entered in any
                            court of competent jurisdiction. Each party agrees that the arbitration proceedings and
                            results of such proceedings shall be kept confidential. This provision shall not be
                            construed to preclude any party from seeking injunctive relief in a court of competent
                            jurisdiction to protect its rights pending an outcome in arbitration.

                        </p>

                        <p>
THESE TERMS PROVIDE THAT ALL DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING
                            ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT, VIA YOUR ACCEPTANCE OF THESE DISPUTE RESOLUTION
                            PROVISIONS, YOU WAIVE ANY RIGHT TO A JURY TRIAL, AS WELL AS YOUR RIGHT TO BRING, JOIN OR
                            PARTICIPATE AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS ACTION SUIT OR MULTI-PARTY
                            ARBITRATION BROUGHT AGAINST US, ANY PERSON RELATED TO US OR A SERVICE PROVIDER USED BY US TO
                            PROVIDE THE SERVICE. THUS, YOU GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR
                            RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN OR BRING CLASS ACTIONS. YOUR RIGHTS
                            WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. THE INFORMATION PROVIDED
                            IN THIS AGREEMENT IS NOT INTENDED TO CONTAIN A COMPLETE EXPLANATION OF THE CONSEQUENCES OF
                            ARBITRATION.
                            {' '}

                        </p>



                            </div>
                        </div>
                    </div>
                </div>




            </PageWrapper>
        );
    }
};

Terms.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Terms;
